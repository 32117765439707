import React from "react";
import { connect } from "react-redux";
import Gallery from "react-grid-gallery";
import { GALLARY } from "../../shared/models/gallery";
import Header from "../../components/common/header/Header";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import { Footer } from "../../components/common/footer/Footer";
import { Helmet } from "react-helmet";

export const Gallary = () => {
  const groupedImages = GALLARY.reduce((groups, image) => {
    if (!groups[image.title]) {
      groups[image.title] = [];
    }
    groups[image.title].push(image);
    return groups;
  }, {});

  return (
    <div>
      <Helmet>
        <title>
          Asian Roots | Explore Gallery | Best Skin Care | Hygiene-Friendly |
          Calming Ambience | (CC-72)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="Your premier destination for top-tier Medi-Spa treatments, led by expert dermatologists with all safety & hygiene protocols. Explore the calming ambience of our clinics. Visit our gallery for more! (CC- 169)"
        />
      </Helmet>
      <Header />
      <Breadcrum heading={"Gallery"} />
      <section className="gallery-section">
        <div className="container">
          <div className="row">
            <div className="gallary-sec">
            {Object.keys(groupedImages).map((title, index) => (
                <div key={index} className="gallery-container">
                  <h1 className="image-gallery-title">{title}</h1>
                  <div className="image-galley-wrapper">
                  {groupedImages[title].map((image, imageIndex) => (
                    <div className="img-container">
                    {/* <div> */}
                        {
                          <p className="image-title">{image?.imageTitle}</p>
                        }
                    {/* </div> */}
                    <div key={imageIndex} className={`image-card ${image.isMd?"ext-height":"nor-height"}`} >
                        <img src={image.thumbnail} alt={image.caption}  />
                    </div>
                    </div>
                  ))}

                  </div>
                </div>
              ))}
              {/* {GALLARY.map((image, index) => {
                const isNewTitle = image.title !== previousTitle;
                previousTitle = image.title;
                return (
                  <div key={index} className="image-card">
                    {isNewTitle && (
                      <h1 className="image-gallary-title">{image.title}</h1>
                    )}
                    <div className="gallery-images">
                      <img src={image.thumbnail} alt={image.caption} loading="lazy"  />
                    </div>
                  </div>
                );
              })} */}
              {/* <Gallery
                images={GALLARY}
                enableLightbox={true}
                // maxRows={3}
                backdropClosesModal
                // currentImage={5}
                // isOpen={ true}
              /> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Gallary);
