export const BLOG = [
  {
    image: '/assets/images/blog-1.png',
    treatment: 'Skin Treatment',
    heading: 'Bridal Facials for Luminous, Radiant Looking Brides',
    paragraph:
      '“Thanks to the girls at the reception –they always take good care of me. So I am a little affectionate and boisterous in greeting & talking to them! I’ve been a client of Asian Roots for the past 6 years, and always love to come here.”',
  },
  {
    image: '/assets/images/blog-1.png',
    treatment: 'Spa Treatment',
    heading: 'Bridal Facials for Luminous, Radiant Looking Brides',
    paragraph:
      '“Thanks to the girls at the reception –they always take good care of me. So I am a little affectionate and boisterous in greeting & talking to them! I’ve been a client of Asian Roots for the past 6 years, and always love to come here.”',
  },
  {
    image: '/assets/images/blog-1.png',
    treatment: 'Skin Treatment',
    heading: 'Bridal Facials for Luminous, Radiant Looking Brides',
    paragraph:
      '“Thanks to the girls at the reception –they always take good care of me. So I am a little affectionate and boisterous in greeting & talking to them! I’ve been a client of Asian Roots for the past 6 years, and always love to come here.”',
  },
]
