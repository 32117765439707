import React, { useState } from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import Faq from "../../components/faq/Faq";
import OfficeLocations from "../../components/officelocation/OfficeLocations";
import WhyUs from "../../components/whyus/WhyUs";
import { SERVICES } from "../../shared/models/service";
import { useSelectAllService } from "../../shared/hooks/UseService";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput, SelectBox } from "../../components/Form/Form";
import AppointmentForm from "../../components/appointment-form/AppointmentForm";
import { Helmet } from "react-helmet";
import HubspotContactForm from "../../components/HubspotContactForm";

const Service = ({ match }) => {
  const [data] = useSelectAllService();
  const { all_services } = data;
  const [selectedService, setSelectedService] = useState(null);
  useEffect(() => {
    if (all_services) {
      const filterData = all_services.filter(
        (item) => item.slug == match.params.slug
      );
      if (filterData && filterData.length > 0) {
        setSelectedService(filterData[0]);
      }
    }
  }, [all_services, match.params.slug]);

  return (
    <div>
      {selectedService && (
        <Helmet>
          <title>
            {selectedService.meta_title
              ? selectedService.meta_title
              : selectedService.name}
          </title>
          {selectedService.meta_description && (
            <meta
              name="description"
              content={selectedService.meta_description}
            />
          )}
        </Helmet>
      )}
      <Header />
      <Breadcrum heading={selectedService && selectedService.name} />
      <section className="service-name">
        <div className="bg-shape-1">
          <img src="/assets/images/services.png" alt="Asian Roots" />
        </div>
        {selectedService && (
          <>
            <div className="container">
              <div className="row ">
                <div className="col-md-5 mx-auto">
                  <div className="service-heading mt-3 ">
                    <h2> {selectedService.title}</h2>
                    {selectedService.content &&
                      renderHTML(selectedService.content)}
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="single-service mt-4">
                    {/* <AppointmentForm page={selectedService.title} /> */}
                    <HubspotContactForm
                      region="na1"
                      portalId="9398359"
                      formId="351b082f-92eb-44e4-afb8-42ac61fa184a"
                    />
                    {/* <Formik
                      initialValues={{
                        name: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required("Required"),
                      })}
                      onSubmit={async (
                        values,
                        { setSubmitting, resetForm }
                      ) => {
                        setSubmitting(true);

                        resetForm();
                        setSubmitting(false);
                      }}
                    >
                      {(formik) => {
                        console.log(formik);
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-12">
                                <TextInput
                                  label="Name"
                                  name="name"
                                  type="text"
                                  placeholder="Name"
                                />
                              </div>
                              <div className="col-md-12">
                                <TextInput
                                  label="Email"
                                  name="email"
                                  type="text"
                                  placeholder="Email"
                                />
                              </div>
                              <div className="col-md-12">
                                <TextInput
                                  label="Phone"
                                  name="phone"
                                  type="text"
                                  placeholder="Phone"
                                />
                              </div>
                              <div className="col-md-12">
                                <SelectBox label="Branch" name="branch">
                                  <option value=""> --Select-- </option>
                                  <option value="Gurugram">Gurugram</option>
                                  <option value="Delhi">Delhi</option>
                                  <option value="Kolkata">Kolkata</option>
                                </SelectBox>
                              </div>
                              <div className="col-md-12">
                                <TextArea
                                  label="Message"
                                  name="message"
                                  type="text"
                                ></TextArea>
                              </div>
                            </div>
                            <div className="text-center">
                              {" "}
                              <button type="submit" className="btn-quote">
                                BOOK AN APPOINTMENT
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
      <WhyUs />

      <OfficeLocations />
      <Footer />
    </div>
  );
};

export default Service;
