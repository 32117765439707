import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrum } from '../../components/common/breadcrum/Breadcrum'
import { Footer } from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import { Helmet } from "react-helmet";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 Not Found - Asian Roots</title>
      </Helmet>
      <Header />


      <section className>
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <div className="nofound-iamge">
                <img src="/assets/images/404.png" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="thankyou-content text-center">
                <div className="contact-us  text-center">
                  <a className="btn btn-contact-us" href="/">
                    Return Homepage
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
