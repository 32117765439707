import React from 'react'
import Breadcrum from '../../components/common/breadcrum/Breadcrum'
import { Footer } from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import Faq from '../../components/faq/Faq'
import { Helmet } from "react-helmet";

function AllFaq() {
  return (
    <div>
      <Helmet>
        <title>FAQ's - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={'Frequently Asked Questions'} />
      <Faq hideTitle={true} />
      <Footer />
    </div>
  )
}

export default AllFaq
