import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextArea, TextInput, SelectBox } from "../Form/Form";
import { useCreateContact } from "../../shared/hooks/UseContact";
import { convertToFormData } from "../../shared/upload";

function AppointmentForm({ page }) {
  const [data, addData] = useCreateContact();
  const submitFormClicked = async (values) => {
    values.page = page;
    const data = await convertToFormData({ values });
    await addData(data, values);
    window.location.href = `/thankyou`;
  };
  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          phone: "",
          email: "",
          branch: "",
          service: "",
          message: "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          phone: Yup.string().required("Required"),
          email: Yup.string().required("Required"),
          branch: Yup.string().required("Required"),
          service: Yup.string().required("Required"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          await submitFormClicked(values);
          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          console.log(formik);
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <TextInput
                    // label="Name"
                    name="name"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-12">
                  <TextInput
                    // label="Phone"
                    name="phone"
                    type="text"
                    placeholder="Phone"
                  />
                </div>
                <div className="col-md-12">
                  <TextInput
                    // label="Email"
                    name="email"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div className="col-md-12">
                  <SelectBox
                    // label="Branch"
                    name="branch"
                    onChange={(e) => {
                      formik.setFieldValue("branch", e.target.value);
                      formik.setFieldValue("service", "");

                    }}>
                    <option option="">Select Branch</option>
                    <option value={"Delhi"}>Delhi</option>
                    <option value={"Gurgaon"}>Gurgaon</option>
                    <option value={"Kolkata"}>Kolkata</option>
                  </SelectBox>
                </div>

                <div className="col-md-12">
                  <SelectBox
                    // label="Branch"
                    name="service">
                    <option option="">Select Service</option>


                    {formik.values.branch == "Gurgaon" && (
                      <>
                        <option value={"Salon"}>Salon</option>
                        <option value={"MediSpa"}>Medi Spa</option>
                      </>
                    )}
                    {formik.values.branch == "Delhi" && (
                      <>
                        <option value={"MediSpa"}>Medi Spa</option>
                      </>
                    )}
                    {formik.values.branch == "Kolkata" && (
                      <>
                        <option value={"MediSpa"}>Medi Spa</option>
                      </>
                    )}

                  </SelectBox>
                </div>


                <div className="col-md-12">
                  <TextArea
                    //  label="Message" 
                    name="message"
                    type="text"
                    placeholder="Message"
                  />
                </div>
                <div className="text-center">
                  <button type="submit" className="btn-quote">
                    BOOK AN APPOINTMENT
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AppointmentForm;
