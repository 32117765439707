import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import ModalForm from "../../modal/BookAppoinment";

import { useSelectAllService } from "../../../shared/hooks/UseService";
import { useSelectAllCategory } from "../../../shared/hooks/UseCategory";

export const Footer = (props) => {
  const [cat_data] = useSelectAllCategory();
  const { all_categorys, all_categorys_loading } = cat_data;

  const [mainSpaCat, setMainSpaCat] = useState(null);
  const [mainSkinCat, setMainSkinCat] = useState(null);
  useEffect(() => {
    if (all_categorys) {
      const filteredSpaCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Spa & Salon Services"
      );
      setMainSpaCat(filteredSpaCat[0]);
      const filteredSkinCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Skin Services"
      );
      setMainSkinCat(filteredSkinCat[0]);
    }
  }, [all_categorys]);

  const [service_data] = useSelectAllService();
  const { all_services, all_services_loading } = service_data;
  return (
    <div>
      <section className="still-have ptb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="still-iamge">
                <img src="/assets/images/still-have.png" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="still-content">
                <h3>Still Have Questions?</h3>
                <p>
                  We welcome your questions! If you have any questions or
                  concerns about the matter above, please do not hesitate to
                  contact us.
                </p>
                <div className="contact-us">
                  <Link to="/contact-us" className="btn btn-contact-us">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end still have question */}
      <footer>
        <section className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="top-contant">
                  <ul>
                    {/* <li>
                      <a href="tel:919560503981">
                        <i className="fa-solid fa-mobile-screen-button" />

                        <span>Delhi- +91-9560503981</span>
                      </a>
                    </li>
                    <li>
                      <a href="tel:919650048036">
                        <i className="fa-solid fa-mobile-screen-button" />

                        <span>Gurgaon- +91-9650048036</span>
                      </a>
                    </li> */}
                    <li>
                      <a href="tel:+91-9899894500">
                        <i className="fa-solid fa-mobile-screen-button" />
                        <span>Book Appointment - +91-989 989 4500</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pull-right">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/asianrootsclinic/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/AsianRootsClinics/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://twitter.com/AsianRootsClini"
                        target="_blank"
                      >
                        <i className="fa-brands fa-twitter" />
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="https://www.youtube.com/@asianrootsclinics?sub_confirmation=1"
                        target="_blank"
                      >
                        <i className="fa-brands fa-youtube footer-icon" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-main">
                  <h3>Laser Hair Reduction </h3>
                  <ul>
                    <li>
                      <Link to="/services/laser-hair-reduction" className="dropdown-item">

                        Laser Hair Reduction
                      </Link>
                    </li>
                  </ul>

                  <hr />
                </div>
                <div className="footer-main">
                  <h3>Skin Services </h3>
                  <ul>
                    <li>
                      <Link to="/services/photofacial" className="dropdown-item" >

                        Power Glow Facials
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/oxyfacial-facials" className="dropdown-item">

                        Oxyfacial
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/oxyfacial-oxygeneo-facials" className="dropdown-item">

                        OxyGeneo Facial
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hydrafacial" className="dropdown-item">

                        Hydrafacial
                      </Link>

                    </li>
                    <li>
                      <Link to="/services/chemical-peels" className="dropdown-item">

                        Chemical Peels
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/unisex-day-spa" className="dropdown-item">

                        Inara by Forrest Essential Range of
                        Facials
                      </Link>

                    </li>
                    <li>
                      <Link to="/services/unisex-day-spa" className="dropdown-item">

                        The Luxurious Thalgo Facials
                      </Link>

                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="footer-main">
                  <h3> Body & Face Contouring &amp; Sculpting</h3>
                  <ul>
                    <li>
                      <Link to="/services/facial-contouring" className="dropdown-item" >

                        Body & Face Contouring &amp; Sculpting
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-main">
                  <h3>Salon</h3>
                  <ul>
                    <li>
                      <Link to="/services/unisex-salon" className="dropdown-item" >

                        Hair and Scalp Rituals and Treatments
                      </Link>

                    </li>
                    {
                      // <li>
                      //   <Link to="/services/unisex-day-spa" className="dropdown-item" >

                      //     Body Wraps and Rituals
                      //   </Link>

                      // </li>
                    }
                    <li>
                      <Link to="/services/unisex-salon" className="dropdown-item" >

                        Nail Bar
                      </Link>

                    </li>
                  </ul>
                  <hr />
                </div>
                <div className="footer-main">
                  <h3>Anti-Aging Solutions </h3>
                  <ul>
                    <li>
                      <Link to="/services/micro-anti-ageing
                                    " className="dropdown-item">

                        Age Reversal
                      </Link>

                    </li>
                  </ul>
                  <div className="footer-main">
                    <h3>Non-Invasive</h3>
                    <ul>
                      <li>
                        <Link to="/services/exilis-elite" className="dropdown-item" >

                          Exilis Elite
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/photofacial" className="dropdown-item" >

                          Photofacial
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/thermage" className="dropdown-item" >

                          Thermage
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/hifu" className="dropdown-item" >

                          HIFU
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/ultherapy" className="dropdown-item" >

                          Ultherapy
                        </Link>

                      </li>
                    </ul>
                  </div>
                  <div className="footer-main">
                    <h3>Minimal-Invasive</h3>
                    <ul>
                      {/* <li>
                        <Link to="/services/botox" className="dropdown-item" >

                          Botox</Link>

                      </li> */}
                      <li>
                        <Link to="/services/filers" className="dropdown-item" >

                          Fillers
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/thread-lifts" className="dropdown-item" >

                          Thread Lifts
                        </Link>

                      </li>
                      <li>
                        <Link to="/services/skin-boosters" className="dropdown-item">

                           Skin Boosters Etc
                        </Link>

                      </li>
                    </ul>
                  </div>
                  <hr />
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="need-help">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-logo">
                  <Link to={"/"}>
                    <img src="/assets/images/logo.png" />
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div className="need-help-content">
                  <h3>Disclaimer</h3>
                  <p>
                    Results may vary from client to client depending on various
                    factors like skin type, skin sensitivity, follow up of
                    treatment and precautions taken before and after treatment.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="need-help-b text-center mt-3">
                  <Link to="/contact-us" className="btn btn-needhelp">
                    Need Help ?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="copyright-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="copyright">
                  <p>© 2022 All Rights Reserved. Asianrootsclinic.</p>
                </div>
              </div>
              <div className="col-md-9">
                <div><div className="whatsapp-footer"><div className="whatsapp-fix"><a href="https://wa.me/9899894500?text=Hi,I have reached out here through your website. Would you like to know more about Asian Roots treatments? "><img src="/assets/images/whatsapp.png" /></a>
                
                </div></div></div>

                <div className="privacy-link">
                  <ul>
                    <li>
                      <Link to="/gift-card">Gift Vouchers</Link>
                    </li>
                    <li>
                      <Link to="/policies/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/policies/refund-and-cancellation-policy">
                        Refund & Cancellation Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/policies/terms-and-conditions">
                        Terms &amp; Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ’s</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
