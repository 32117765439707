import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import { useSelectAllService } from "../../shared/hooks/UseService";
import Loading from "../loading/Loading";
import Mobile_nav from "../mobilenav/Mobile_nav";

const Navbar = (props) => {
  const [megamenu, setMegamenu] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [mobile, setmobile] = useState(false);

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  const isSticky = (e) => {
    const header = document.querySelector(".navigatin");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  const [cat_data] = useSelectAllCategory();
  const { all_categorys, all_categorys_loading } = cat_data;

  const [mainSpaCat, setMainSpaCat] = useState(null);
  const [mainSkinCat, setMainSkinCat] = useState(null);
  useEffect(() => {
    if (all_categorys) {
      const filteredSpaCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Spa & Salon Services"
      );
      setMainSpaCat(filteredSpaCat[0]);
      const filteredSkinCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Skin Services"
      );
      setMainSkinCat(filteredSkinCat[0]);
    }
  }, [all_categorys]);

  const [service_data] = useSelectAllService();
  const { all_services, all_services_loading } = service_data;
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    setMobileMenu(false);
  }, [window.location.pathname]);
  console.log("LOCATION", window.location.pathname);
  return (
    <div>
      {all_services && all_categorys ? (
        <>
          <section className="navigatin">
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <Link to={"/"}>
                      <img src="/assets/images/logo.png" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-10 mx-auto">
                  <nav className="navbar fl navbar-expand-lg navbar-light sticky-top">
                    <div className="container">
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#mobile_nav"
                        aria-controls="mobile_nav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => setMobileMenu(!mobileMenu)}
                      >
                        {mobileMenu ? (
                          <i className="fa fa-close"></i>
                        ) : (
                          <i class="fas fa-bars"></i>
                        )}
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="mobile_nav"
                        style={{ display: mobile ? "block" : "none" }}
                      >
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0 float-md-right"></ul>
                        <ul className="navbar-nav navbar-light">
                          <li className="nav-item">
                            <Link className="nav-link" to={"/"}>
                              Home
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to={"/about-us"}
                              className="nav-link"
                              href="#"
                            >
                              About Us
                            </Link>
                          </li>
                          <li
                            className="nav-item dropdown megamenu-li dmenu"
                            onMouseEnter={() => {
                              setMegamenu(true);
                            }}
                            onMouseLeave={() => {
                              setMegamenu(false);
                            }}
                          >
                            <a
                              className="nav-link dropdown-toggle"
                              href
                              id="dropdown01"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Medical Aesthetic Services
                            </a>
                            <div
                              className="dropdown-menu megamenu sm-menu border-top"
                              aria-labelledby="dropdown01"
                              style={{
                                display: megamenu ? "block" : "none",
                                maxWidth: "930px",
                              }}
                            >
                              <div
                                className="row display-inherit"
                                style={{ display: "inherit" }}
                              >
                                <div className="col-sm-6 col-lg-4 border-right nac">
                                  <h6>Age Reversal Treatments</h6>
                                  <ul className="ul-margin">
                                    <li>
                                      <Link
                                        to="/services/exilis-elite"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Exilis Elite
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/photofacial"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Photofacial
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/hifu"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        HIFU
                                      </Link>
                                    </li>
                                  </ul>
                                  <div>
                                    <h6>Injectables</h6>
                                    <ul className="ul-margin">
                                      <li>
                                        <Link
                                          to="/services/botox"
                                          className="dropdown-item"
                                        >
                                          <i class="fas fa-circle"></i>
                                          Botox
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/services/fillers"
                                          className="dropdown-item"
                                        >
                                          <i class="fas fa-circle"></i>
                                          Fillers
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/services/skin-boosters"
                                          className="dropdown-item"
                                        >
                                          <i class="fas fa-circle"></i>
                                          Skin Boosters
                                        </Link>
                                      </li>
                                      <li>
                                        <Link
                                          to="/services/thread-lifts"
                                          className="dropdown-item"
                                        >
                                          <i class="fas fa-circle"></i>
                                          Thread Lifts
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to="/services/vampire-facial-lift" className="dropdown-item">
                                          <i class="fas fa-circle"></i>
                                          Vampire Face Lift
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="col-sm-6 col-lg-4 border-right nac">
                                  <h6>Skin Rejuvenation Treatments</h6>
                                  <ul className="ul-margin">
                                    <li>
                                      <Link
                                        to="/services/oxyfacial-facials"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Oxy Facial
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/services/clearlift" className="dropdown-item">
                                        <i class="fas fa-circle"></i>
                                        Clearlift
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/thalgo-medi-facials"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Thalgo Medi Facials
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/services/microdermabrasion" className="dropdown-item">
                                        <i class="fas fa-circle"></i>
                                        Microdermabrasion
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/hydrafacial"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Hydra Facial
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/services/rfc-wart-removal-cauterization" className="dropdown-item">
                                        <i class="fas fa-circle"></i>
                                        RFC Wart removal & Cauterization
                                      </Link>
                                    </li>
                                  </ul>

                                  {/* <div className="non">
                                    <h5>Minimal-Invasive</h5>
                                    <ul>
                                      <li>
                                        <Link to="/services/micro-anti-ageing" className="dropdown-item" >
  <i class="fas fa-circle"></i> 
                                          Botox</Link>

                                      </li>
                                      <li>
                                        <Link to="/services/micro-anti-ageing" className="dropdown-item" >
  <i class="fas fa-circle"></i> 
                                          Fillers
                                        </Link>

                                      </li>
                                      <li>
                                        <Link to="/services/silhouette-lift" className="dropdown-item" >
  <i class="fas fa-circle"></i> 
                                          Thread Lifts
                                        </Link>

                                      </li>
                                      <li>
                                        <Link to="/services/skin-brightening-skin-revival" className="dropdown-item">
  <i class="fas fa-circle"></i> 
                                          PRP and Skin Boosters Etc
                                        </Link>

                                      </li>
                                    </ul>
                                  </div> */}
                                </div>
                                <div className="col-sm-6 col-lg-4 border-right nac">
                                  <h6>Body And Face Sculpting Treatments</h6>
                                  <ul className="ul-margin">
                                    <li>
                                      <Link
                                        to="/services/coolsculpting"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Coolsculpting
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/exilis-elite"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Exilis Elite
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/services/lymphatism" className="dropdown-item">
                                        <i class="fas fa-circle"></i>
                                        Lymphatism
                                      </Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/services/micro-anti-ageing" className="dropdown-item" >
  <i class="fas fa-circle"></i> 
                                          HIFU
                                        </Link>

                                      </li> */}
                                    {/* <li>
                                        <Link to="/services/ultherapy" className="dropdown-item" >
  <i class="fas fa-circle"></i> 
                                          Ultherapy
                                        </Link>

                                      </li> */}
                                  </ul>
                                </div>
                                <div className="col-sm-6 col-lg-4 border-right nac">
                                  <h6>Laser Treatments</h6>
                                  <ul className="ul-margin">
                                    <li>
                                      <Link
                                        to="/services/laser-hair-reduction"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Laser Hair Reduction
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/services/laser-skin-rejuvenation" className="dropdown-item">
                                        <i class="fas fa-circle"></i>
                                        Laser Skin Rejuvenation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/laser-pigmentation"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Laser Pigmentation
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/tattoo-reduction-treatment"
                                        className="dropdown-item test"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Tattoo Reduction Treatment
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="col-sm-6 col-lg-4 border-right nac"
                                  style={{ marginTop: "-7rem" }}
                                >
                                  <h6>Chemical Peels</h6>
                                  <ul className="ul-margin">
                                    <li>
                                      <Link
                                        to="/services/glycolic-chemical-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Glycolic Chemical 
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to="/services/nomelan"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Nomelan Peel
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to="/services/black-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Black peel
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        to="/services/salicylic-chemical-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Salicylic Peel
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/cosmelan-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Cosmelan Peel
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/yellow-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Yellow Peel
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        to="/services/azelan-peel"
                                        className="dropdown-item"
                                      >
                                        <i class="fas fa-circle"></i>
                                        Azelan Peel
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <div className="col-sm-6 col-lg-4 border-right nac">
                                  <div className="nav-image">
                                    <img src="/assets/images/navbar.png"></img>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          {
                            //   <li
                            //   className="nav-item dmenu dropdown"
                            //   onMouseEnter={() => {
                            //     setDropdown(true)
                            //   }}
                            //   onMouseLeave={() => {
                            //     setDropdown(false)
                            //   }}
                            // >
                            //   <a
                            //     className="nav-link dropdown-toggle"
                            //     id="navbarDropdown"
                            //     role="button"
                            //     data-toggle="dropdown"
                            //     aria-haspopup="true"
                            //     aria-expanded="false"
                            //   >
                            //     Gurgaon Salon
                            //   </a>
                            //   <div
                            //     className="dropdown-menu sm-menu"
                            //     aria-labelledby="navbarDropdown"
                            //     style={{
                            //       display: dropdown ? 'block' : 'none',
                            //     }}
                            //   >
                            //     <Link to="/services/unisex-salon" className="dropdown-item" >
                            //       <i className="fa fa-arrow-right" />
                            //       Hair and Scalp Rituals and Treatments
                            //     </Link>
                            //     <Link to="/services/unisex-salon" className="dropdown-item" >
                            //       <i className="fa fa-arrow-right" />
                            //       Nail Bar
                            //     </Link>
                            //     {
                            //       //   <Link to="/services/unisex-salon" className="dropdown-item">
                            //       //   <i className="fa fa-arrow-right" />
                            //       //   Unisex Salon
                            //       // </Link>
                            //       // <Link to="/services/unisex-day-spa" className="dropdown-item">
                            //       //   <i className="fa fa-arrow-right" />
                            //       //   Unisex Day Spa
                            //       // </Link>
                            //       // <Link to="/services/bridal-studio" className="dropdown-item">
                            //       //   <i className="fa fa-arrow-right" />
                            //       //   Bridal Studio
                            //       // </Link>
                            //     }
                            //   </div>
                            // </li>
                          }

                          <li className="nav-item">
                            <Link className="nav-link" to={"/awards"}>
                              Awards
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={"/gallery"}>
                              Gallery
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={"/blog"}>
                              Blog
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to={"/contact-us"}>
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <Mobile_nav mobileMenuDrop={mobileMenu} />
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </div>
  );
};

export default Navbar;
