export const TESTIMONIAL = [
  {
    image: '/assets/images/client-1.png',

    name: 'Aruna Chopra',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas placeat obcaecati accusamus nesciunt ullam nostrum quis eveniet, ab maiores provident nihil ducimus beatae at. Eaque ratione eos optio maiores tempore?',
  },
  {
    image: '/assets/images/client-1.png',

    name: 'Shubham Pathak',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas placeat obcaecati accusamus nesciunt ullam nostrum quis eveniet, ab maiores provident nihil ducimus beatae at. Eaque ratione eos optio maiores tempore?',
  },
  {
    image: '/assets/images/client-1.png',

    name: 'Sohail Khan',
    paragraph:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas placeat obcaecati accusamus nesciunt ullam nostrum quis eveniet, ab maiores provident nihil ducimus beatae at. Eaque ratione eos optio maiores tempore?',
  },
]
