import React, { useEffect, useRef } from "react";


const HubspotContactForm = ({ region, portalId, formId }) => {
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region,
          portalId,
          formId,
          target: "#hubspotForm",
        });
      }
    });

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [region, portalId, formId]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.innerHTML = `<div id="hubspotForm" class="hubspot-form"></div>`;

      const formElement = formRef.current.querySelector("#hubspotForm form");
      if (formElement) {
        formElement.addEventListener("submit", handleFormSubmit);
      }
    }

    return () => {
      const formElement = formRef.current.querySelector("#hubspotForm form");
      if (formElement) {
        formElement.removeEventListener("submit", handleFormSubmit);
      }
    };
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted");
  };

  return <div ref={formRef}></div>;
};

export default HubspotContactForm;
