import React from "react";
import AppointmentForm from "../../components/appointment-form/AppointmentForm";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";

function PlatinumMembership() {
  return (
    <div>
      <Header />
      <Breadcrum heading={"Platinum Membership"} />
      <section className="gold-membership ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="gold-content">
                <h4>
                  Be a part of our inner circle, join our exclusive Platinum
                  Privilege Club! Membership entitles you to Rs.1,25,000/- worth
                  of Spa & Salon Services for the year. Once the amount of
                  Rs.1,25,000/- is exhausted within the one year period, you
                  shall continue to avail 15% discount on Spa & Salon Services
                  till the end of the year. The Platinum Privilege Card is
                  available on the payment of <strong>Rs.1,00,000/-</strong>
                  only.
                </h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="gold-form-apply">
                <div className="contant-us-form">
                  <AppointmentForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default PlatinumMembership;
