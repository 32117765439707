import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export const Membership = (props) => {
  return (
    <div>
      <section className="golden ptb-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <Link to="/gold-membership">
                <div className="golden-mem">
                  <img src="assets/images/golden-membership.jpg" />
                </div>
              </Link>
            </div>
            <div className="col-md-5">
              <Link to="/platinum-membership">
                <div className="golden-mem">
                  <img src="assets/images/plantinum.jpg" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Membership)
