import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useSelectAllBanner } from "../../shared/hooks/UseBanner";
import { URI } from "../../domain/constant";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: 30 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 30 }}
      onClick={onClick}
    />
  );
}

const Banner = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const [data] = useSelectAllBanner();
  const { all_banners, all_banners_loading } = data;

  return (
    <div className="banner-sec">
      <Slider {...settings} style={{ width: "100%", padding: 0 }}>
        {all_banners &&
          all_banners.map((item) => {
            return (
              <div>
                <Link to={`/services/${item.service?.slug}`}>
                  <img src={`${URI}${item.image}`} style={{ width: "100%" }} />
                </Link>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Banner;
