import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

export const CallToAction = (props) => {
  return (
    <div>
      <section className="call-to-action">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="call-to-action-content">
                <span>
                  Skincare protection is a small price to pay for healthy skin!
                </span>
                <h3>Beautiful skin starts here!</h3>
                <p>
                  If you want to make your skin glow, your best bet is to take
                  care of it. Visit Asian Roots Today!
                </p>
                <div className="call-action">
                  <Link to="/contact-us" className="btn btn-action">
                    BOOK AN APPOINTMENT
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="call-to-action-image">
                <img src="assets/images/call-to-action.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CallToAction)
