import React from "react";
import AppointmentForm from "../../components/appointment-form/AppointmentForm";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { Helmet } from "react-helmet";

function GoldMembership() {
  return (
    <div>
      <Helmet>
        <title>Gold Membership - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={"Gold Membership"} />
      <section className="gold-membership ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="gold-content">
                <h4>
                  Sign up for our exclusive join our Gold Privilege Club!
                  Membership entitles you to avail Rs.62,500/- worth of Spa &
                  Salon Services for the period of one year. Once the initial
                  amount is exhausted within the one-year period, you shall
                  still continue to avail 10% off on the Spa & Salon Services
                  till the year is over. The Gold Privilege Card is available on
                  payment of <strong>Rs.50,000/-</strong> only.
                </h4>
              </div>
            </div>
            <div className="col-md-6">
              <div className="gold-form-apply">
                <div className="contant-us-form">
                  <AppointmentForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default GoldMembership;
