import React from 'react'
import { connect } from 'react-redux'

export const WhyUs = (props) => {
  return (
    <div>
      <section className="why-us ptb-50">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2>Why Us</h2>
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-md-3">
              <div className="main-why-us">
                <div className="why-us-icon">
                  <img src="/assets/images/whuus-1.png" />
                </div>
                <div className="why-content">
                  <h4>LARGEST TEAM OF CERTIFIED DERMATOLOGISTS</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="main-why-us">
                <div className="why-us-icon">
                  <img src="/assets/images/why-us-2.png" />
                </div>
                <div className="why-content">
                  <h4>US-FDA APPROVED EQUIPMENTS</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="main-why-us">
                <div className="why-us-icon">
                  <img src="/assets/images/whyus-3.png" />
                </div>
                <div className="why-content">
                  <h4>HIGHLY STANDARDISED PROTOCOLS</h4>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="main-why-us">
                <div className="why-us-icon">
                  <img src="/assets/images/why-us-4.png" />
                </div>
                <div className="why-content">
                  <h4>3 LAKH + HAPPY CLIENTS</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(WhyUs)
