import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrum from "../components/common/breadcrum/Breadcrum";
import { Footer } from "../components/common/footer/Footer";
import Header from "../components/common/header/Header";
import Services from "../components/services/Services";
import { URI } from "../domain/constant";
import { useSelectAllService } from "../shared/hooks/UseService";
import { Helmet } from "react-helmet";

function AllSpaServices() {
  const [data] = useSelectAllService();
  const { all_services, all_services_loading } = data;
  const [spaServices, setSpaServices] = useState(null);
  const [skinServices, setSkinServices] = useState(null);

  useEffect(() => {
    if (all_services) {
      const filteredData = all_services.filter(
        (item) => item.category && item.category.name == "Skin Services"
      );
      if (filteredData && filteredData.length > 0) {
        setSkinServices(filteredData);
      }
      const filteredDataSpa = all_services.filter(
        (item) => item.category && item.category.name == "Spa & Salon Services"
      );
      if (filteredDataSpa && filteredDataSpa.length > 0) {
        setSpaServices(filteredDataSpa);
      }
    }
  }, [all_services]);
  return (
    <div>
      <Helmet>
        <title>All Spa Services - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={"Spa Services"} />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {spaServices && (
                <>
                  <div className="row">
                    {spaServices &&
                      spaServices.map((item, index) => {
                        return (
                          <div className="col-md-3">
                            <div className="service-child">
                              <div className="circle-image text-center">
                                <Link to={`/services/${item.slug}`}>
                                  <img src={`${URI}${item.image}`} />
                                </Link>
                              </div>
                              <div className="serivie-heading">
                                <p> {item.name} </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  <hr />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default AllSpaServices;
