import React from "react";
import { connect } from "react-redux";
import Blog from "../../components/blog/Blog";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { Helmet } from "react-helmet";

export const Blogs = (props) => {
  return (
    <div>
      <Helmet>
        <title>
          Asian Roots Blog | Skin Specialists in Delhi, Gurgaon, Kolkata |
          Skincare Tips from Beauty Experts | Concerns | Remedies | (CC- 102)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="Your premier destination for top-tier Medi-Spa treatments, led by expert dermatologists. Discover holistic beauty through our reliable and expert-curated blog, offering insightful skincare and wellness tips. (CC- 182)"
        />
      </Helmet>
      <Header />
      <Breadcrum heading={"Blog"} />
      <Blog />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
