import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { URI } from '../../domain/constant'
import { useSelectAllService } from '../../shared/hooks/UseService'

const Services = (props) => {
  const [data] = useSelectAllService()
  const { all_services, all_services_loading } = data
  const [spaServices, setSpaServices] = useState(null)
  const [skinServices, setSkinServices] = useState(null)


  useEffect(() => {
    if (all_services) {
      const filteredData = all_services.filter(
        (item) => item.category && item.category.name == 'Skin Services',
      )
      if (filteredData && filteredData.length > 0) {
        setSkinServices(filteredData)
      }
      const filteredDataSpa = all_services.filter(
        (item) => item.category && item.category.name == 'Spa & Salon Services',
      )
      if (filteredDataSpa && filteredDataSpa.length > 0) {
        setSpaServices(filteredDataSpa)
      }
    }
  }, [all_services])

  return (
    <div>
      <section className="service ptb-80">
        <div className="container">
          {/* <div className="row">
            <div className="col-md-12">
              <div className="heading">
                <h2>Our Services</h2>
              </div>
            </div>
          </div> */}
          {skinServices && (
            <>
              <div className="row">
              <div className="col-md-12">
              <div className="heading pb-md-4">
                  <h2>Medical Aesthetic Treatments</h2>
                </div>
                </div>


                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/laser-hair-reduction">
                        <img src="/assets/images/services/image-1659767320570.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Laser Hair Reduction</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/coolsculpting">
                        <img src="/assets/images/services/image-1659444483455.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>CoolSculpting</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/exilis-elite">
                        <img src="/assets/images/services/image-1659444586270.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Exilis Elite</p>
                    </div>
                  </div>
                </div>


                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/hydrafacial">
                        <img src="/assets/images/services/image-1659445489566.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Hydrafacial</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/oxyfacial-facials">
                        <img src="/assets/images/services/image-1659442044079.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Oxyfacial/Oxygeneo Facials</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/photofacial">
                        <img src="/assets/images/services/image-1659440268185.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Photofacial</p>
                    </div>
                  </div>
                </div>


                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <Link to="/skin-services">
                      <div className="circle-image circle-bg">
                        <h3>More</h3>
                      </div>
                    </Link>
                    <div className="serivie-heading">
                      <p>More</p>
                    </div>
                  </div>
                </div>

              </div>


              <hr />
            </>
          )}





          {spaServices && (
            <div className="spa-service">
              <div className="row row-justify">
              <div className="col-md-12">
              <div className="heading pb-md-4">
                  <h2>Salon - Gurgaon</h2>
                </div>
                </div>


                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/unisex-salon">
                        <img src="/assets/images/services/brunette-woman-getting-her-hair-cut.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Haircut/Style</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/unisex-salon">
                        <img src="/assets/images/services/beautiful-keratin-treated-hair.webp" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Keratin</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/unisex-salon">
                        <img src="/assets/images/services/female-hairdresser-making-hairstyle-redhead-woman-beauty-salon.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Hair Botox</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-3 col-6">
                  <div className="service-child">
                    <div className="circle-image">
                      <Link to="/services/unisex-salon">
                        <img src="/assets/images/services/hair-extensions-client-close-up-beauty-salon.jpg" />
                      </Link>
                    </div>
                    <div className="serivie-heading">
                      <p>Hair Extensions</p>
                    </div>
                  </div>
                </div>

                {
                  //   <div className="col-md-3 col-6">
                  //   <div className="service-child">
                  //     <div className="circle-image">
                  //       <Link to="/services/unisex-day-spa">
                  //         <img src="/assets/images/services/girl-massage-spa-salon.jpg" />
                  //       </Link>
                  //     </div>
                  //     <div className="serivie-heading">
                  //       <p>Signature Asian Roots Massage </p>
                  //     </div>
                  //   </div>
                  // </div>
                  // <div className="col-md-3 col-6">
                  //   <div className="service-child">
                  //     <div className="circle-image">
                  //       <Link to="/services/unisex-day-spa">
                  //         <img src="/assets/images/services/therapist-pouring-massage-oil-spa.jpg" />
                  //       </Link>
                  //     </div>
                  //     <div className="serivie-heading">
                  //       <p>Royal Thai Therapy</p>
                  //     </div>
                  //   </div>
                  // </div>
                  // <div className="col-md-3 col-6">
                  //   <div className="service-child">
                  //     <div className="circle-image">
                  //       <Link to="/services/unisex-day-spa">
                  //         <img src="/assets/images/services/spa-massage-shoulder-young-beautiful-woman-beauty-salon.jpg" />
                  //       </Link>
                  //     </div>
                  //     <div className="serivie-heading">
                  //       <p>Oriental Therapies and Wraps </p>
                  //     </div>
                  //   </div>
                  // </div>
                  // <div className="service-child">
                  //   <Link to="/spa-services">
                  //     <div className="circle-image circle-bg">
                  //       <h3>More</h3>
                  //     </div>
                  //   </Link>
                  //   <div className="serivie-heading">
                  //     <p>More</p>
                  //   </div>
                  // </div>
                }
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default Services
