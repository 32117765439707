import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelectAllCategory } from "../../shared/hooks/UseCategory";
import { useSelectAllService } from "../../shared/hooks/UseService";
import Loading from "../loading/Loading";
function Mobile_nav({ mobileMenuDrop }) {
  const [cat_data] = useSelectAllCategory();
  const { all_categorys, all_categorys_loading } = cat_data;
  const [mainSpaCat, setMainSpaCat] = useState(null);
  const [mainSkinCat, setMainSkinCat] = useState(null);
  useEffect(() => {
    if (all_categorys) {
      const filteredSpaCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Spa & Salon Services"
      );
      setMainSpaCat(filteredSpaCat[0]);
      const filteredSkinCat = all_categorys.filter(
        (item) => !item.parent_category && item.name == "Skin Services"
      );
      setMainSkinCat(filteredSkinCat[0]);
    }
  }, [all_categorys]);
  const [activeSkinMenu, setActiveSkinMenu] = useState(null);
  const [activeSpaMenu, setActiveSpaMenu] = useState(null);
  const [subMenu, setSubMenu] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [service_data] = useSelectAllService();
  const { all_services, all_services_loading } = service_data;
  return (
    <div>
      <div className="" style={{ display: mobileMenuDrop ? "block" : "none" }}>
        <div className="mobile-header">
          <div className="mobile-header-a">
            <div className="desk-start">
              <div className="mobile-logo">
                <img src="/assets/images/logo.png"></img>
              </div>
              <hr></hr>
              {!activeSkinMenu && !activeSpaMenu && (
                <ul className="main-category">
                  <li>
                    <a className="dropdown-item" href="/">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a onClick={() => setActiveSkinMenu(true)}>
                        Medical Aesthetic Services
                      </a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>

                  <li>
                    <a href="/awards">Awards</a>
                  </li>
                  <li>
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul>
              )}

              {activeSkinMenu && !subMenu && (
                <ul className="main-category" style={{ display: "block" }}>
                  <li>
                    <a
                      style={{
                        padding: "5px 20px",
                        background: "rgb(255, 242, 242)",
                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "1px solid rgb(255, 242, 242)",
                        borderLeft: "none",
                        borderImage: "initial",
                      }}
                      onClick={() => setActiveSkinMenu(false)}
                    >
                      <i className="fa fa-angle-left" /> Go Back to Main
                    </a>
                  </li>
                  <li onClick={() => setSubMenu("Skin Services")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Age Reverasal Treatments</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                  <li onClick={() => setSubMenu("Face Sculpting")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Injectables</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                  <li
                    onClick={() => setSubMenu("Skin Rejuvenation Treatments")}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Skin Rejuvenation Treatments</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                  <li
                    onClick={() =>
                      setSubMenu("Body And Face Sculpting Treatments")
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Body And Face Sculpting Treatments</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                  <li onClick={() => setSubMenu("Laser Treatments")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Laser Treatments</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                  <li onClick={() => setSubMenu("Chemical Peels")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a>Chemical Peels</a>
                      <button
                        style={{
                          padding: "5px 20px",
                          background: "rgb(255, 255, 255)",
                          borderTop: "none",
                          borderRight: "none",
                          borderBottom: "1px solid rgb(255, 242, 242)",
                          borderLeft: "none",
                          borderImage: "initial",
                        }}
                      >
                        <i className="fa fa-angle-right" />
                      </button>
                    </div>
                  </li>
                </ul>
              )}

              {activeSpaMenu && (
                <ul className="main-category" style={{ display: "block" }}>
                  <li>
                    <a
                      style={{
                        padding: "5px 20px",

                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "1px solid rgb(255, 242, 242)",
                        borderLeft: "none",
                        borderImage: "initial",
                      }}
                      onClick={() => setActiveSpaMenu(false)}
                    >
                      <i className="fa fa-angle-left" /> Go Back to Services
                    </a>
                  </li>

                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Link
                        to={`/services/unisex-salon`}
                        className="dropdown-item"
                      >
                        Unisex Salon
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    ></div>
                  </li>
                </ul>
              )}

              {activeSkinMenu && subMenu && (
                <ul className="main-category" style={{ display: "block" }}>
                  <li>
                    <a
                      style={{
                        padding: "5px 20px",

                        borderTop: "none",
                        borderRight: "none",
                        borderBottom: "1px solid rgb(255, 242, 242)",
                        borderLeft: "none",
                        borderImage: "initial",
                      }}
                      onClick={() => {
                        setActiveSpaMenu(false);
                        setActiveSkinMenu(false);
                        setSubMenu(null);
                      }}
                    >
                      <i className="fa fa-angle-left" /> Go Back to Services
                    </a>
                  </li>
                  {subMenu == "Skin Rejuvenation Treatments" && (
                    <>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/oxyfacial-facials"
                            className="dropdown-item"
                          >
                            Oxy Facial
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/clearlift"
                            className="dropdown-item"
                          >
                            Clearlift
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/thalgo-medi-facials"
                            className="dropdown-item"
                          >
                            Thalgo Medi Facials
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/microdermabrasion"
                            className="dropdown-item"
                          >
                            Microdermabrasion
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/hydrafacial"
                            className="dropdown-item"
                          >
                            Hydra Facial
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/rfc-wart-removal-cauterization"
                            className="dropdown-item"
                          >
                            RFC Wart removal & Cauterization
                          </Link>
                        </div>
                      </li>
                    </>
                  )}
                  {subMenu == "Skin Services" && (
                    <>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to={`/services/exilis-elite`}
                            className="dropdown-item"
                          >
                            Exilis Elite
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to={`/services/photofacial`}
                            className="dropdown-item"
                          >
                            Photofacial
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link to={`/services/hifu`} className="dropdown-item">
                            HIFU
                          </Link>
                        </div>
                      </li>
                    </>
                  )}

                  {subMenu == "Body And Face Sculpting Treatments" && (
                    <>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/coolsculpting"
                            className="dropdown-item"
                          >
                            Coolsculpting
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link
                            to="/services/exilis-elite"
                            className="dropdown-item"
                          >
                            Exilis Elite
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Link to="/services/lymphatism" className="dropdown-item">
                            Lymphatism
                          </Link>
                        </div>
                      </li>
                    </>
                  )}

                  {subMenu == "Laser Treatments" && (
                    <>
                      <li>
                        <Link
                          to="/services/laser-hair-reduction"
                          className="dropdown-item"
                        >
                          Laser Hair Reduction
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/laser-skin-rejuvenation"
                          className="dropdown-item"
                        >
                          Laser Skin Rejuvenation
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/laser-pigmentation"
                          className="dropdown-item"
                        >
                          Laser Pigmentation
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/tattoo-reduction-treatment"
                          className="dropdown-item"
                        >
                          Tattoo Reduction Treatment
                        </Link>
                      </li>
                    </>
                  )}
                  {subMenu == "Face Sculpting" && (
                    <>
                      <li>
                        <Link to="/services/botox" className="dropdown-item">
                          Botox
                        </Link>
                      </li>
                      <li>
                        <Link to="/services/fillers" className="dropdown-item">
                          Fillers
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/skin-boosters"
                          className="dropdown-item"
                        >
                          Skin Boosters
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/thread-lifts"
                          className="dropdown-item"
                        >
                          Thread Lifts
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/vampire-facial-lift"
                          className="dropdown-item"
                        >
                          Vampire Face Lift
                        </Link>
                      </li>
                    </>
                  )}

                  {subMenu == "Chemical Peels" && (
                    <>
                      <li>
                        <Link
                          to="/services/glycolic-chemical-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Glycolic Peel
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/services/nomelan"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Nomelan Peel
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/services/black-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Black peel
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/services/salicylic-chemical-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Salicylic Peel
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/cosmelan-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Cosmelan Peel
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/yellow-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Yellow Peel
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/azelan-peel"
                          className="dropdown-item"
                        >
                          <i class="fas fa-circle"></i>
                          Azelan Peels
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mobile_nav;
