export const GALLARY = [

  {
    src: '\assets\images\gallary\Ambience\Delhi.JPG',
    thumbnail: '/assets/images/gallary/Ambience/Delhi.JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    isMd:false,
    imageTitle:"Delhi",
    caption: 'Celebrity Makeover',
    title:"Ambience",
  },
  {
    src: '\assets\images\gallary\Ambience\Delhi(1).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Delhi(1).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    isMd:false,
    imageTitle:"Delhi",
    caption: 'Celebrity Makeover',
    title:"Ambience",

  },

  {
    src: '\assets\images\gallary\Ambience\Delhi(2).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Delhi(2).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    isMd:false,
    imageTitle:"Delhi",
    caption: 'Celebrity Makeover',
    title:"Ambience",

  },
  {
    src: '\assets\images\gallary\Ambience\Delhi(3).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Delhi(3).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    imageTitle:"Delhi",

    isMd:false,


  },
  {
    src: '\assets\images\gallary\Ambience\Delhi(4).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Delhi(4).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:false,
    imageTitle:"Delhi",


  },
  {
    src: '\assets\images\gallary\Ambience\Delhi(5).jpg',
    thumbnail: '/assets/images/gallary/Ambience/Delhi(5).jpg',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:true,
    imageTitle:"Delhi",



  },
  {
    src: '\assets\images\gallary\Ambience\Gurgaon.jpg',
    thumbnail: '/assets/images/gallary/Ambience/Gurgaon.jpg',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    imageTitle:"Gurgaon",

    isMd:true,


  },
  {
    src: '\assets\images\gallary\Ambience\Gurgaon(1).jpg',
    thumbnail: '/assets/images/gallary/Ambience/Gurgaon(1).jpg',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:true,
    imageTitle:"Gurgaon",



  },
  {
    src: '\assets\images\gallary\Ambience\Gurgaon(2).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Gurgaon(2).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:true,
    imageTitle:"Gurgaon",

  },
  {
    src: '\assets\images\gallary\Ambience\Kolkata.JPG',
    thumbnail: '/assets/images/gallary/Ambience/Kolkata.JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:false,
    imageTitle:"Kolkata",


  },
  {
    src: '\assets\images\gallary\Ambience\Kolkata(1).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Kolkata(1).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:false,
    imageTitle:"Kolkata",



  },
  {
    src: '\assets\images\gallary\Ambience\Kolkata(2).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Kolkata(2).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:false,
    imageTitle:"Kolkata",


  },
  {
    src: '\assets\images\gallary\Ambience\Kolkata (3).JPG',
    thumbnail: '/assets/images/gallary/Ambience/Kolkata (3).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Ambience",
    isMd:false,
    imageTitle:"Kolkata",



  },
 
  {
    src: '\assets\images\gallary\BeforeAfter\Chemical Peel _ Clearlift.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/Chemical Peel _ Clearlift.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"Chemical Peel Clearlift",


  },

  
  {
    src: '\assets\images\gallary\BeforeAfter\Chin Augmentation- Filler.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/Chin Augmentation- Filler.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"Chin Augmentation Filler",



  },


  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting _ Exilis Elite 2.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting _ Exilis Elite 2.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting Exilis Elite ",



  },

  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting _ Exilis Elite.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting _ Exilis Elite.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting Exilis Elite ",


  },

  
  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting 2.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting 2.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting ",


  },
 
  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting 3.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting 3.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:true,
    imageTitle:"CoolSculpting ",



  },

  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting 4.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting 4.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:true,
    imageTitle:"CoolSculpting ",


  },

  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting 5.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting 5.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:true,
    imageTitle:"CoolSculpting ",


  },
 
  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting- Arms (Cover).png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting- Arms (Cover).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting Arms ",


  },
  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting- Axillary Puff.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting- Axillary Puff.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting Axillary Puff",



  },
  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting- Thighs.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting- Thighs.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting Thighs ",

  },


  {
    src: '\assets\images\gallary\BeforeAfter\CoolSculpting.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/CoolSculpting.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"CoolSculpting",


  },

  {
    src: '\assets\images\gallary\BeforeAfter\Dermapen 2.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/Dermapen 2.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",
    isMd:false,
    imageTitle:"Dermapen",



  },

  {
    src: '\assets\images\gallary\BeforeAfter\Dermapen.png',
    thumbnail: '/assets/images/gallary/BeforeAfter/Dermapen.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"TREATMENT Results",   
     isMd:false,
     imageTitle:"Dermapen",



  },
  {
    src: '\assets\images\gallary\TreatmentShots\Clearlift.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Clearlift.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Clearlift",


  },
  {
    src: '\assets\images\gallary\TreatmentShots\Dermatologist at work.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Dermatologist at work.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Dermatologist at work",



  },
  {
    src: '\assets\images\gallary\TreatmentShots\Exilis Elite (Cover).png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Exilis Elite (Cover).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Exilis Elite ",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\Exilis Elite.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Exilis Elite.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Exilis Elite ",


  },
  {
    src: '\assets\images\gallary\TreatmentShots\Exilis Elite(1).png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Exilis Elite(1).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Exilis Elite ",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\Facial.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Facial.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,    
    imageTitle:"Facial",



  },
  {
    src: '\assets\images\gallary\TreatmentShots\Facial(1).png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Facial(1).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:true,
    imageTitle:"Facial",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\HIFU.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/HIFU.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:true,
    imageTitle:"HIFU",



  },

  {
    src: '\assets\images\gallary\TreatmentShots\Invasive Age-Reversal .png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Invasive Age-Reversal .png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:true,
    imageTitle:"Invasive Age-Reversal",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\IV GLUTA.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/IV GLUTA.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:true,
    imageTitle:"IV GLUTA",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\Laser Hair Reduction.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Laser Hair Reduction.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Laser Hair Reduction",

  },

  {
    src: '\assets\images\gallary\TreatmentShots\Laser Hair Reduction.JPG',
    thumbnail: '/assets/images/gallary/TreatmentShots/Laser Hair Reduction.JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Laser Hair Reduction",


  },
  

  {
    src: '\assets\images\gallary\TreatmentShots\Laser Hair Reduction(1).JPG',
    thumbnail: '/assets/images/gallary/TreatmentShots/Laser Hair Reduction(1).JPG',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Laser Hair Reduction",


  },

  {
    src: '\assets\images\gallary\TreatmentShots\MDA.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/MDA.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"MDA",

  },

  {
    src: '\assets\images\gallary\TreatmentShots\MDA(1).png',
    thumbnail: '/assets/images/gallary/TreatmentShots/MDA(1).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"MDA",


  },
  
  {
    src: '\assets\images\gallary\TreatmentShots\Party Peel.png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Party Peel.png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Party Peel",


  },
  

  {
    src: '\assets\images\gallary\TreatmentShots\Party Peel(1).png',
    thumbnail: '/assets/images/gallary/TreatmentShots/Party Peel(1).png',
    thumbnailWidth: '100%',
    thumbnailHeight: '200px',
    caption: 'Celebrity Makeover',
    title:"Treatment Shots",
    isMd:false,
    imageTitle:"Party Peel",


  },
  
  


  // {
  //   src: '/assets/images/gallary/skin-care.jpg',
  //   thumbnail: '/assets/images/gallary/skin-care.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '200px',
  //   caption: 'Celebrity Makeover',
    
  // },
  // {
  //   src: '/assets/images/gallary/875x508_2.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_2.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_3.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_3.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_4.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_4.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_5.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_5.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_6.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_6.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_7.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_7.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_8.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_8.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
  // {
  //   src: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnail: '/assets/images/gallary/875x508_9.jpg',
  //   thumbnailWidth: '100%',
  //   thumbnailHeight: '100%',
  //   caption: 'Celebrity Makeover',
  // },
]
