import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";


export const About = (props) => {
  return (
    <div>

      <section className="about ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="about-us-imaage">
                <img src="assets/images/about-us-image.jpg" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-us-content">
                <span>About Asian Roots</span>
                <h2>We Are Asian Roots</h2>
                <p>
                  Asian Roots is a highly awarded chain of Medi-Spa clinics that offers a unique combination of highly trained aesthetic doctors and dermatologists, offering a series of exclusive treatments for Permanent Laser Hair Reduction, Body Contouring, Facial Aesthetics, Beauty, and Hair Restoration Treatments.
                </p>

                <p>
                  At the core of Asian Roots, philosophy is <strong>“EXCELLENCE”</strong> & the highest level of customer satisfaction, this is reflected in everything we do. We use only the most sophisticated and scientifically proven and certified machines like CoolSculpting, Soprano Ice, Harmony Excel Pro Exilis Elite, Clearlift, Hydra Facial Tower, RF Ellman, and many more which are performed by highly trained aestheticians. Our centers have an exclusive tie-up with the Global Leader in Hair Restoration and Transplant chain of clinics, i.e., DHI International Medical Group.
                </p>

                <p>
                  The best part about our services is that the unique “Asian Roots” experience comes at very affordable prices. Asian Roots is present in three locations: <strong>Delhi, Kolkata & Gurgaon.</strong>
                </p>
                <div className="book-an">
                  <div className="get">
                    Get expert skin care tips for your skin!
                  </div>
                  <div>
                    {/* <button onclick="{openModal}">Open Modal</button> */}

                    <Link to="/contact-us" href className="btn btn-appoinmtent">
                      BOOK AN APPOINTMENT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(About)
