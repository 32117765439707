import React from "react";
import { connect } from "react-redux";

export const OurTeam = (props) => {
  return (
    <div>
      <section className="our-team mt-3 mb-30">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2>Our Doctors</h2>
            </div>
          </div>
          <div className="row flex-center">
            {
              //     <div className="col-md-4">
              //     <div className="step-image bs">
              //         <div className="image bg-doctor">
              //             <img src="/assets/images/doctors-img/Dr-Bhawana.jpg" alt="" />
              //         </div>
              //         <div className="mt-3">
              //             <div className=" doctor text-center">
              //                 <h3>Dr. Bhavna</h3>
              //                 <p>Dr. Bhavna is an expert in the field of Aesthetic Medicine, having more than a decade of vast experience working in various skin clinics. She has trained extensively in the use of all Skin & Hair Treatments such as different Lasers, Q-Switch, Peels, Micro-needling, Radio Frequency, Thermage, Botox, Fillers, IV Glutathione, and PRP Injections, to name a few.
              //                     With an empathetic & kind nature, She makes her patients feel at ease with her professional and unbiased advice on treatments. Keeping precision and perfection the most important factor, She makes sure her treatments are accurate and results-oriented. She keeps abreast with the latest technologies in dermatology and is always open to new learnings. She has attended multiple training sessions with Allergan for Botox and Fillers, in India and abroad, and is a certified skilled injector.
              //                 </p>
              //             </div>
              //         </div>
              //     </div>
              // </div>
            }
            <div className="col-md-4 mx-auto">
              <div className="step-image bs">
                <div className="image bg-doctor">
                  <img src="/assets/images/doctors-img/Dr-Kuntal.jpg" alt="" />
                </div>
                <div className="mt-3">
                  <div className=" doctor text-center">
                    <h3>Dr. Kuntal</h3>
                    <p>
                      Dr. Kuntal is a highly seasoned and experienced Aesthetic
                      Dermatologist. He is also highly skilled in cosmetic
                      procedures such as anti-acne treatments, anti-pigmentation
                      therapies and scar reduction treatments, aesthetic
                      therapies like Age-Reversal Invasive Treatment, fillers,
                      skin rejuvenation treatments, hair growth treatments like
                      Mesotherapy, and Hair Transplants, Laser procedures for
                      skin rejuvenation, hair reduction and scar reductions. He
                      has successfully performed thousands of hair transplant
                      and restoration procedures over the last 10 years with the
                      DHI and Asian Roots group.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mx-auto">
              <div className="step-image bs">
                <div className="image bg-doctor">
                  <img src="/assets/images/doctors-img/dr-manu.jpg" alt="" />
                </div>
                <div className="mt-3">
                  <div className=" doctor text-center">
                    <h3>Dr. Manu</h3>
                    <p>
                      Dr. Manu is an MBBS and professional aesthetic physician
                      with over 10 years of experience in aesthetic dermatology.
                      Consulting and treating a variety of skin, hair, and nail
                      disorders and providing the most appropriate and
                      well-suited treatment for his patient's aesthetic needs.
                      He keeps himself well-informed with the modern
                      technologies in dermatology and is always open to new
                      learnings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);
