import React, { useEffect } from 'react'
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes'
import PrivateRoutes from './shared/Routes/PrivateRoutes'
import PageNotFound from './containers/notfound/PageNotFound'
import Home from './containers/home/Home'

import AboutUs from './containers/aboutus/AboutUs'
import Service from './containers/services/Service'
import ContactUs from './containers/contactus/ContactUs'

import Awards from './containers/awards/Awards'
import Gallary from './containers/gallary/Gallary'
import Blogs from './containers/blog/Blogs'
import GiftCard from './containers/giftcard/GiftCard'
import SingleBlog from './containers/blog/SingleBlog'
import Policy from './containers/policies/Policy'
import Faq from './components/faq/Faq'
import AllFaq from './containers/faq/AllFaq'
import AllSkinServices from './containers/AllSkinServices'
import AllSpaServices from './containers/AllSpaServices'
import GoldMembership from './containers/membership/GoldMembership'
import PlatinumMembership from './containers/membership/PlatinumMembership'
import Thankyou from './containers/thankyou/Thankyou'
import CanonicalUrl from './components/common/CanonicalUrl/CanonicalUrl'


function App() {
  return (
    <Provider store={store}>
      <Router>
      <CanonicalUrl />
        <Switch>
          <Route exact path={'/'} component={Home} />
          <Route exact path={'/about-us'} component={AboutUs} />
          <Route exact path={'/services/:slug'} component={Service} />
          <Route exact path={'/contact-us'} component={ContactUs} />
          <Route exact path={'/blog'} component={Blogs} />
          <Route exact path={'/awards'} component={Awards} />
          <Route exact path={'/gift-card'} component={GiftCard} />
          <Route exact path={'/gallery'} component={Gallary} />
          <Route exact path={'/blog/:slug'} component={SingleBlog} />
          <Route exact path={'/policies/:slug'} component={Policy} />
          <Route exact path={'/faq'} component={AllFaq} />
          <Route exact path={'/skin-services'} component={AllSkinServices} />
          <Route exact path={'/spa-services'} component={AllSpaServices} />
          <Route exact path={'/gold-membership'} component={GoldMembership} />
          <Route exact path={'/thankyou'} component={Thankyou} />
          <Route
            exact
            path={'/platinum-membership'}
            component={PlatinumMembership}
          />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
