import React from "react";
import { connect } from "react-redux";

import Slider from "react-slick";
import renderHTML from "react-render-html";
import { BLOG } from "../../shared/models/blog";
import { useSelectAllBlog } from "../../shared/hooks/UseBlog";
import { URI } from "../../domain/constant";
import { Link } from "react-router-dom";

const Blog = ({ isHome }) => {
  var blogs = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [blog_data] = useSelectAllBlog();

  const { all_blogs, blog_loading } = blog_data;

  return (
    <div>
      <section className="blog ptb-50">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2> Our Latest Blogs </h2>
            </div>
          </div>
          <div className="row mt-20 blog-container">
            {isHome ? (
              <div>
                <Slider {...blogs}>
                  {all_blogs &&
                    all_blogs.map((item, index) => {
                      return (
                        <div>
                          <div className="main-blog blog-content">
                            <div className="blog-iamge">
                              <Link to={`/blog/${item.slug}`}>
                                <img src={`${URI}${item.image}`} />
                              </Link>
                            </div>
                            <div>
                              {/* <span>{item.category}</span> */}
                              <h3>{item.title}</h3>
                              {/* <p>{item.content && renderHTML(item.content)}</p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            ) : (
              <div className="row">
                {all_blogs &&
                  all_blogs.map((item, index) => {
                    return (
                      <div className="col-md-4">
                        <div className="main-blog blog-content">
                          <div className="blog-iamge">
                            <Link to={`/blog/${item.slug}`}>
                              <img src={`${URI}${item.image}`} />
                            </Link>
                          </div>
                          <div className="blog-content-wrapper">
                            {/* <span>{item.category}</span> */}
                            <h3>{item.title}</h3>
                            {/* <p>{item.content && renderHTML(item.content)}</p> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
