import React from 'react'

function Loading() {
  return (
    <div>
      <div className="loading">
        <div className="loading-content">
          <img src="/assets/images/logo.png"></img>
          <div className="mt-5">
            <img src="/assets/images/loader.gif"></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
