import React from "react";

function Topbar() {
  return (
    <div>
      <header>
        <section className="tob-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="top-contant">
                  <ul>
                    {/* <li>
                                            <a href="tel:919560503981">
                                                <i className="fa-solid fa-mobile-screen-button" />

                                                <span>Delhi- +91-9560503981</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="tel:919650048036">
                                                <i className="fa-solid fa-mobile-screen-button" />

                                                <span>Gurgaon- +91-9650048036</span>
                                            </a>
                                        </li> */}
                    <li>
                      <a href="tel:+91-9899894500">
                        <i className="fa-solid fa-mobile-screen-button" />
                        <span>Book Appointment - +91-989 989 4500</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="pull-right">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/asianrootsclinic/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/AsianRootsClinics/"
                        target="_blank"
                      >
                        <i className="fa-brands fa-instagram" />
                      </a>
                    </li>
                    {/* <li>
                                            <a
                                                href="https://twitter.com/AsianRootsClini"
                                                target="_blank"
                                            >
                                                <i className="fa-brands fa-twitter" />
                                            </a>
                                        </li> */}
                    <li>
                      <a
                        href="https://www.youtube.com/@asianrootsclinics?sub_confirmation=1"
                        target="_blank"
                      >
                        <i className="fa-brands fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    </div>
  );
}

export default Topbar;
