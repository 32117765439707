import React, { useState } from "react";
import { useSelectAllFaq } from "../../shared/hooks/UseFaq";

const Faq = ({ hideTitle }) => {
  const [faq, setFaq] = useState(0);

  const [data] = useSelectAllFaq();

  const { all_faqs, all_faqs_loading } = data;
  return (
    <div>
      <section className="faq ptb-50">
        <div className="container">
          <div className="row">
            {!hideTitle && (
              <div className="heading">
                <h2>Frequently Asked Questions</h2>
              </div>
            )}
          </div>
          <div className="row mt-20">
            <div className="col-md-7 mx-auto">
              {all_faqs &&
                all_faqs.map((item, index) => {
                  return (
                    <div className="main-accor" onClick={() => setFaq(index)}>
                      <div className="faq-flex">
                        <div className="">
                          <h3> {item.question} </h3>
                        </div>
                        <div className>
                          {faq === index ? (
                            <i className="fa fa-minus"></i>
                          ) : (
                            <i className="fa fa-plus"></i>
                          )}
                        </div>
                      </div>
                      <div
                        className="accor-para"
                        style={{ display: faq === index ? "block" : "none" }}
                      >
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
