import React from "react";
import { connect } from "react-redux";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import OfficeLocations from "../../components/officelocation/OfficeLocations";

import AppointmentForm from "../../components/appointment-form/AppointmentForm";

import { Helmet } from "react-helmet";
import HubspotContactForm from "../../components/HubspotContactForm";

export const ContactUs = (props) => {
  return (
    <div>
      <Helmet>
        <title>ContactUs - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={"Contact Us"} />
      <section className="contact-us-page">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="contact-us-heading">
                <span>CONTACT NOW </span>
                <h2>Have Question? Write a Message</h2>
                <p>We will catch you as early as we receive the message</p>
              </div>
              <div className="contant-us-form">
                {/* <AppointmentForm page={"Contact Us"} /> */}
                <HubspotContactForm
                  region="na1"
                  portalId="9398359"
                  formId="351b082f-92eb-44e4-afb8-42ac61fa184a"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <OfficeLocations />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
