import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick'
import { TESTIMONIAL } from '../../shared/models/testimonial'
import { useSelectAllTestimonial } from '../../shared/hooks/UseTestimonial'
import { URI } from '../../domain/constant'

export const Testimonial = ({ isHome }) => {
  var testimonial = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const [data] = useSelectAllTestimonial()
  const { all_testimonials, all_testimonials_loading } = data
  return (
    <div>
      <section className="testimonial ptb-50">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2>Trust of Thousands of Clients</h2>
            </div>
          </div>
          <div className="row main-testimonial">
            {isHome ? (
              <div className="">
                <Slider {...testimonial}>
                  {all_testimonials &&
                    all_testimonials.map((testi) => {
                      return (
                        <div className="mar3">
                          <div className="testimonial-box ml-3">
                            <div className="testimonial-iamge">
                              {/* <img src={`${URI}${testi.image}`} /> */}
                              <p>{testi.testimonial}</p>
                              <hr />
                              <h3>{testi.name}</h3>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </Slider>
              </div>
            ) : (
              <div>
                {all_testimonials &&
                  all_testimonials.map((testi) => {
                    return (
                      <div>
                        <div className="col-md-4">
                          <div>
                            <div className="testimonial-box">
                              <div className="testimonial-iamge">
                                {/* <img src={`${URI}${testi.image}`} /> */}
                                <p>{testi.testimonial}</p>
                                <hr />
                                <h3>{testi.name}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonial)
