import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import renderHTML from "react-render-html";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import { Header } from "../../components/common/header/Header";
import { useSelectAllPolicy } from "../../shared/hooks/UsePolicy";
import { Helmet } from "react-helmet";

const Policy = ({ match }) => {
  const [data] = useSelectAllPolicy();
  const { all_policys, all_policys_loading } = data;

  const [selectedPage, setSelectedPage] = useState(null);
  useEffect(() => {
    if (all_policys) {
      const filteredData = all_policys.filter(
        (item) => item.slug == match.params.slug
      );
      if (filteredData) {
        setSelectedPage(filteredData[0]);
      }
    }
  }, [match.params.slug, all_policys]);
  console.log(selectedPage);

  return (
    <div>
      <Helmet>
        <title>Policy - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={selectedPage && selectedPage.title} />
      <section className="policy ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="policies-m">
                {selectedPage &&
                  selectedPage.content &&
                  renderHTML(selectedPage.content)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Policy;
