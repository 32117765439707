import React from "react";
import { connect } from "react-redux";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { Helmet } from "react-helmet";

export const Awards = (props) => {
  return (
    <div>
      <Helmet>
        <title>
          Asian Roots | Best Medi-Spa in Delhi | Award-Winning | Expert
          Dermatologist | Skin | Hair | Body | (CC-80)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="With a team of skilled aesthetic doctors and expert dermatologists, we have earned recognition for our commitment to excellence. Our clinics are best skin care clinics across Delhi, Gurgaon & Kolkata; dedicated to providing an array of exclusive head-to-toe treatments, ensuring a holistic and rejuvenating experience for every individual. (CC- 291)"
        />
      </Helmet>
      <Header />
      <Breadcrum heading={"Awards &  Recognition"} />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="row">
                <div className="col-md-4">
                  <div className="awards-main">
                    <img src="/assets/images/awards/Frame___01.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="awards-main">
                    <img src="/assets/images/awards/Frame___02.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="awards-main">
                    <img src="/assets/images/awards/Frame___03.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="awards-main">
                    <img src="/assets/images/awards/Frame___04.jpg" alt="" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="awards-main">
                    <img src="/assets/images/awards/Frame___05.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Awards);
