import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { URI } from "../../domain/constant";

import { useSelectAllBlog } from "../../shared/hooks/UseBlog";
import { Helmet } from "react-helmet";
export const SingleBlog = ({ match }) => {
  const [blog_data] = useSelectAllBlog();
  const { all_blogs, all_blogs_loading } = blog_data;
  const [selectedBlog, setSelectedBlog] = useState(null);
  useEffect(() => {
    if (all_blogs) {
      const filteredData = all_blogs.filter(
        (item) => item.slug == match.params.slug
      );
      if (filteredData && filteredData.length > 0) {
        setSelectedBlog(filteredData[0]);
      }
    }
  }, [all_blogs, match.params.slug]);

  return (
    <div>
      {selectedBlog && (
        <Helmet>
          <title>{selectedBlog.meta_title ? selectedBlog.meta_title : selectedBlog.title}</title>
          {selectedBlog.meta_description && <meta name="description" content={selectedBlog.meta_description} />}
        </Helmet>
      )}

      <Header />
      <hr></hr>
      <section className="blog_section">
        <div className="container">
          <div className="row">
            {selectedBlog && (
              <>
                <div className="col-md-12">
                  <div className="single-content">
                    <h3>{selectedBlog && selectedBlog.title}</h3>
                  </div>
                </div>
                <div className="single-image">
                  <img src={`${URI}${selectedBlog.image}`} />
                </div>
                <div className="main-blog-single">
                  {selectedBlog.content && renderHTML(selectedBlog.content)}
                </div>
              </>
            )}

            <hr></hr>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SingleBlog);
