import React, { useState } from "react";
import About from "../../components/about/About";
import Banner from "../../components/banner/Banner";
import Blog from "../../components/blog/Blog";
import CallToAction from "../../components/calltoaction/CallToAction";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import Faq from "../../components/faq/Faq";
import Membership from "../../components/membership/Membership";
import OfficeLocations from "../../components/officelocation/OfficeLocations";
import Services from "../../components/services/Services";
import Testimonial from "../../components/testimonial/Testimonial";
import WhyUs from "../../components/whyus/WhyUs";
import { Helmet } from "react-helmet";
import OurTeam from "../ourteam/OurTeam";

function Home() {
  return (
    <div>
      <Helmet>
        <title>
          Best Skin Clinic in Delhi, Gurgaon, Kolkata. Asian Roots: One Stop for
          Body Contouring, Beauty & Facial Aesthetics (CC- 97)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="Asian Roots is a well-known award-winning chain of Medi-Spa clinics, known for its skilled aesthetic doctors and dermatologists. We offer a variety of exclusive head-to-toe treatments. (CC- 159)"
        />
      </Helmet>
      <Header />
      <Banner />
      <Services />
      <About />
      <OurTeam />
      <WhyUs />
      <OfficeLocations />
      <Testimonial isHome={true} />
      <Blog isHome={true} />
      <CallToAction />
      <Faq />
      <Membership />
      <Footer />
    </div>
  );
}

export default Home;
