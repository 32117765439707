import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

export const OfficeLocations = (props) => {
  return (
    <div>
      <section className="office-location ptb-50">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2>Our Locations</h2>
              <p>Wherever you are, we are there for you!</p>
            </div>
          </div>

          <div className="row mt-20">
            <div className="col-md-4">
              <div className="office-location-main">
                <h2>Delhi</h2>
                <div className="office-location-image">
                  <img src="/assets/images/office-location.jpg" />
                </div>
                <div className="mt-2">
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-map" />
                    </div>
                    <a href="https://www.google.com/maps/dir//AsianRoots+Clinic+-+Luxury+Hair+%26+Skin+Clinic+in+Delhi,+A+2,+Block+A+2,+Safdarjung+Enclave,+New+Delhi,+Delhi/@28.5663232,77.156234,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x390d1d92cada8ae9:0xc36ecf7388225f4e!2m2!1d77.1912567!2d28.5663615">
                      <div className>
                        <p>
                          A- 2/25 Safdarjung Enclave,
                          <br /> Delhi 110029{" "}
                        </p>
                      </div>
                    </a>
                  </div>
                  <hr />
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-square-phone" />
                    </div>
                    <div className>
                      <a href="tel:+91-9899894500">
                        <p>+91-989 989 4500</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="office-location-main">
                <h2>Gurgaon</h2>
                <div className="office-location-image">
                  <img src="/assets/images/office-2.jpg" />
                </div>
                <div className="mt-2">
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-map" />
                    </div>
                    <a href="https://www.google.com/maps/dir//Asian+Roots+Luxury+Wellness+Clinic+in+Gurgaon/@28.4481165,77.0638994,13z/data=!3m1!5s0x390d18b45a1fdced:0x860ae9727251f530!4m18!1m12!4m11!1m3!2m2!1d77.09909!2d28.4479656!1m6!1m2!1s0x390d18b451e46df9:0x6d6cb7a066c4c436!2sAsian+Roots+Luxury+Wellness+Clinic+in+Gurgaon,+220+-+221,+2nd+Floor,+South+Point+Mall,+DLF+Golf+Course+Road,,+Gurugram,+Haryana+122009!2m2!1d77.098919!2d28.448121!3m4!1s0x390d18b451e46df9:0x6d6cb7a066c4c436!8m2!3d28.448121!4d77.098919">
                      <div className>
                        <p>
                          220-221, DLF South Point Mall, <br /> Gurgaon 122002
                        </p>
                      </div>
                    </a>
                  </div>
                  <hr />
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-square-phone" />
                    </div>
                    <div className>
                      <a href="tel:+91-9899894500">
                        <p>+91-989 989 4500</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="office-location-main">
                <h2>Kolkata</h2>
                <div className="office-location-image">
                  <img src="/assets/images/office-3.jpg" />
                </div>
                <div className="mt-2">
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-map" />
                    </div>
                    <a href="https://www.google.com/maps/dir//Asian+Roots+Clinic/@22.537709,88.349081,15z/data=!4m12!1m6!3m5!1s0x0:0xd841fb6b6db459da!2sAsian+Roots+Clinic!8m2!3d22.5377797!4d88.3490878!3m4!1s0x0:0xd841fb6b6db459da!8m2!3d22.5377797!4d88.3490878?hl=en-US">
                      <div className>
                        <p>
                          4th Floor, Platinum Mall 31, <br /> Elgin Road Kolkata
                          700020
                        </p>
                      </div>
                    </a>
                  </div>
                  <hr />
                  <div className="office-location-content">
                    <div className>
                      <i className="fa-solid fa-square-phone" />
                    </div>
                    <div className>
                      <a href="tel:+91-9899894500">
                        <p>+91-989 989 4500</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  */}
      {/* end this section */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeLocations);
