import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import Navbar from '../../navbar/Navbar'
import Topbar from '../../topbar/Topbar'
import { useLocation } from 'react-router-dom'

export const Header = (props) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  // console.log(window.location);
  return (
    <div>
      <Topbar />
      <Navbar />
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
