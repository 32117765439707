import React from "react";
import { connect } from "react-redux";
import About from "../../components/about/About";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import { Header } from "../../components/common/header/Header";
import OfficeLocations from "../../components/officelocation/OfficeLocations";
import WhyUs from "../../components/whyus/WhyUs";
import { Helmet } from "react-helmet";

export const AboutUs = (props) => {
  return (
    <div>
      <Helmet>
        <title>
          About Asian Root Clinics: Best Skin Clinic in Delhi, Gurgaon, Kolkata
          | Learn More | Skin, Body, Hair | Safe & Hygienic | (CC- 99)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="Asian Roots is a well-known award-winning chain of Medi-Spa clinics, known for its skilled aesthetic doctors and dermatologists. We offer a variety of exclusive head-to-toe treatments. (CC- 159)"
        />
      </Helmet>
      <Header />
      <Breadcrum heading="About us" />
      <About />

      <OfficeLocations />
      <WhyUs />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
