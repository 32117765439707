import React from "react";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import { Link } from "react-router-dom";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import OfficeLocations from "../../components/officelocation/OfficeLocations";
import { Helmet } from "react-helmet";

function Thankyou() {
  return (
    <div>
      <Helmet>
        <title>
          Asian Roots | Best Medi-Spa in Delhi | Award-Winning | Expert
          Dermatologist | Skin | Hair | Body | (CC-80)
        </title>
      </Helmet>
      <Helmet>
        <meta
          name="description"
          content="With a team of skilled aesthetic doctors and expert dermatologists, we have earned recognition for our commitment to excellence. Our clinics are best skin care clinics across Delhi, Gurgaon & Kolkata; dedicated to providing an array of exclusive head-to-toe treatments, ensuring a holistic and rejuvenating experience for every individual. (CC- 291)"
        />
      </Helmet>
      <Header />
      <Breadcrum heading={"Thank you"} />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <div className="thankyou-iamge">
                <img
                  style={{ borderRadius: "3px" }}
                  src="/assets/images/thanyou-spa.jpg"
                ></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="thankyou-content">
                <h3>Thank you!</h3>
                <p>
                  Thank you for your inquiry regarding our services. Someone
                  from our team will be in touch with you shortly.
                </p>
                <div class="contact-us mt-5 text-center">
                  <Link to="/" class="btn btn-contact-us">
                    Return Homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Helmet>
        <script>fbq('track', "PageView"); fbq('track', "Lead");</script>
      </Helmet>
      <OfficeLocations />
      <Footer />
    </div>
  );
}

export default Thankyou;
