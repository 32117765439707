import { Form, Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import Breadcrum from "../../components/common/breadcrum/Breadcrum";
import Footer from "../../components/common/footer/Footer";
import Header from "../../components/common/header/Header";
import { useSelectAllProduct } from "../../shared/hooks/UseProduct";
import * as Yup from "yup";
import { SelectBox, TextArea, TextInput } from "../../components/Form/Form";
import { useCreateOrder } from "../../shared/hooks/UseOrder";
import { convertToFormData } from "../../shared/upload";
import { Helmet } from "react-helmet";

export const GiftCard = (props) => {
  const [products_data] = useSelectAllProduct();
  const { all_products } = products_data;
  const [data, addData] = useCreateOrder();

  const submitFormClicked = async (values) => {
    const data = await convertToFormData({ values });
    await addData(data);
    // history.push(`/${LINK_URL}`);
  };

  return (
    <div>
      <Helmet>
        <title>Gift Cards - Asian Roots</title>
      </Helmet>
      <Header />
      <Breadcrum heading={"Gift Card"} />
      <section className="ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="gift-card">
                <img src="/assets/images/asian_roots.png" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="gift-form">
                <h2>Gift Card</h2>
                <hr></hr>

                <Formik
                  initialValues={{
                    amount: "2000",
                    sender_name: "",
                    sender_mobile_no: "",
                    sender_email: "",
                    recipient_name: "",
                    recipient_mobile_no: "",
                    recipient_email: "",
                    message_for_recipient: "",
                  }}
                  validationSchema={Yup.object({
                    amount: Yup.string().required("Required"),
                    sender_name: Yup.string().required("Required"),
                    sender_mobile_no: Yup.string().required("Required"),
                    sender_email: Yup.string().required("Required"),
                    recipient_name: Yup.string().required("Required"),
                    recipient_mobile_no: Yup.string().required("Required"),
                    recipient_email: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);

                    await submitFormClicked(values);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-md-12">
                            <SelectBox label="Gift Amount" name="amount">
                              {all_products &&
                                all_products.map((item) => {
                                  return (
                                    <option value={item.amount}>
                                      ₹{item.amount}
                                    </option>
                                  );
                                })}
                            </SelectBox>
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Sender's Name"
                              name="sender_name"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Seller's Mobile No"
                              name="sender_mobile_no"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Sender's Email"
                              name="sender_email"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Recipient's Name"
                              name="recipient_name"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Recipient's Mobile No"
                              name="recipient_mobile_no"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextInput
                              label="Recipient's Email"
                              name="recipient_email"
                              type="text"
                            />
                          </div>
                          <div className="col-md-12">
                            <TextArea
                              label="Message For Recipient"
                              name="message_for_recipient"
                              type="text"
                            />
                          </div>
                          <div className="buynow">
                            <button className="btn btn-bynow">Buy Now</button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GiftCard);
